import React, { useState, useEffect } from 'react';

import PageTemplate from 'template';
import { getPreview } from 'services/previews';
import BlogIndex from 'template/blogIndex';
import WebinarIndex from 'template/webinarIndex';

const PreviewPage = () => {
  const [preview, setPreview] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  useEffect(() => {
    console.error(error);
  }, [error]);

  useEffect(() => {
    const getParam = async () => {
      const params = new URLSearchParams(window.location.search);
      const previewId = params.get('previewId');
      if (previewId) {
        const previewData: any = await getPreview(previewId, (responseError) =>
          setError(responseError),
        );
        setPreview(previewData?.data?.previewData);
      } else {
        setError('No Preview ID was provided.');
      }
    };

    getParam();
  }, []);

  return (
    <main>
      {error && <p>There was an error</p>}
      {preview?.contentType === 'blog' && (
        <BlogIndex
          data={{
            cms: { landing: { data: { attributes: preview?.modifiedData } } },
            isPreview: true,
          }}
        />
      )}
      {preview?.contentType === 'training' && (
        <WebinarIndex
          data={{
            cms: { landing: { data: { attributes: preview?.modifiedData } } },
            isPreview: true,
          }}
        />
      )}
      {preview?.contentType === 'landing' && (
        <PageTemplate
          data={{
            cms: { landing: { data: { attributes: preview?.modifiedData } } },
            isPreview: true,
          }}
        />
      )}
    </main>
  );
};

export default PreviewPage;
